import * as React from "react";
import Progress from "./Progress";

import {FilePond} from 'react-filepond'

import 'filepond/dist/filepond.min.css'
import {PrimaryButton} from "@fluentui/react";
import _ from "lodash";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

type AppState = {
  files: any
}

const apiUrl = process.env.API_URL

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      files: []
    };
  }

  componentDidMount() {
    this.setState({});
  }

  setFiles = (value) => {
    this.setState({files: value});
    console.log(this.state.files)
  }

  attachFilesToMessage = () => {
    let attachment_links_html = []

    _.map(this.state.files, function (file) {
      if (file.status == 5) {
        attachment_links_html.push(`<li><a href='${apiUrl}/attachment/download?uuid=${file.serverId}'>${file.filename}</a></li>`)
      }
    })

    let attachment_html = `<p>К письму прикреплены следующие вложения:</p>
<ul>${attachment_links_html}</ul>`

    Office.context.mailbox.item.body.prependAsync(attachment_html, {coercionType: Office.CoercionType.Html})
  }

  render() {
    const {title, isOfficeInitialized} = this.props;

    const server = {
      url: `${apiUrl}/upload`,
      process: '/process',
      revert: '/process',
      patch: "?uuid=",
    }

    if (!isOfficeInitialized) {
      return (
          <Progress
              title={title}
              logo={require("./../../../assets/logo-filled.png")}
              message="Please sidel oad your addin to see app body."
          />
      );
    }

    const attachFilesButton = () => {
      if (this.state.files.length > 0) {
        return (
            <PrimaryButton onClick={this.attachFilesToMessage}>Прикрепить файлы к письму</PrimaryButton>
        );
      }
      return false;
    }

    return (
        <div className="ms-welcome">
          <FilePond
              files={this.state.files}
              onupdatefiles={this.setFiles}
              allowMultiple={true}
              maxFiles={50}
              chunkUploads={true}
              chunkSize={100000000}
              chunkForce={true}
              server={server}
              storeAsFile={true}
              name="files"
              labelIdle='Перетащите файлы для загрузки <span class="filepond--label-action">Открыть</span>'
              credits={false}
          />
          {attachFilesButton()}
        </div>
    );
  }
}
